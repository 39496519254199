<template>
    <section class="pb-1" style="background-color: #f9f9f9;">
        <div class="banner-container container-md">
            <div class="row justify-content-center" style="padding: 0px;">
                <div class="carousel-container col-12">
                    <a-carousel arrows autoplay>
                        <template #prevArrow>
                            <div class="custom-slick-arrow prev-arrow">
                                <LeftCircleOutlined />
                            </div>
                        </template>
                        <template #nextArrow>
                            <div class="custom-slick-arrow next-arrow">
                                <RightCircleOutlined />
                            </div>
                        </template>
                  
                        <!-- {{products_cate}} -->
                            <div v-for="item in products_cate" :key="item.id">
                                <img :src="require(`@/assets/images/cate/${item.cate_image}`)" alt="Product Image"
                                    class="img-slide" loading="lazy">
                            </div>
                    </a-carousel>
                </div>
            </div>
        </div>
    </section>

    <section class="category-section pt-2 pb-4">
        <div class="container">
            <ProductList :type="`home`" />
        </div>
    </section>
</template>

<script>
import data from '@/assets/data.js';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import ProductList from '@/components/ProductList.vue';

export default {
    name: 'HomePage',
    data() {
        return {
            banner_product: data.banner_product,
            users: data.users,
            products_cate: data.products_cate,
        }
    },
    components: {
        LeftCircleOutlined,
        RightCircleOutlined,
        ProductList
    }

}
</script>
<style scoped>
.no-data {
    text-align: center;
    font-size: 18px;
    color: #888;
    padding: 20px;
}

.no-data p {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
}
.banner-container {
    padding-bottom: 30px
}

.category-section {
    background-color: #F5F5F5;
}

.carousel-container {
    width: 70%;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, .08);
}

.img-slide,
.slick-image {
    width: 100%;
    height: 100%;
}

a.more {
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
}

.no-underline {
    text-decoration: none;
}

.section-header,
.more {
    border-bottom: 2px solid;
}

.prev-arrow,
.next-arrow {
    z-index: 1
}

.prev-arrow {
    left: -35px;
}

.next-arrow {
    right: -35px;
}


:deep(.slick-slide div) {
    outline: none;
}

:deep(.ant-carousel .slick-next::before),
:deep(.ant-carousel .slick-prev::before) {
    display: none;
}

:deep(.slick-slide) {
    text-align: center;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
    height: auto;
}

:deep(.slick-arrow.custom-slick-arrow) {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #0d0c0c;
    /* background-color: rgba(72, 108, 149, 0.11); */
    background-color: transparent;
    transition: ease all 0.3s;
    opacity: 0.3;
    z-index: 1;
}

/* :deep(.slick-arrow.custom-slick-arrow:before) {
    display: none;
} */

:deep(.slick-arrow.custom-slick-arrow:hover) {
    color: #000000;
    opacity: 0.5;
}

:deep(.slick-slide h3) {
    color: #fff;
}

:deep(.ant-carousel .slick-dots li button) {
    background-color: #454545;
}

:deep(.ant-carousel .slick-dots li.slick-active button) {
    background-color: #000000;
}

:deep(.ant-carousel .slick-dots-bottom) {
    bottom: -30px !important;
}

:deep(.ant-carousel .slick-dots li button) {
    width: 10px;
    height: 10px;
    border-radius: 50% !important;
}

@media only screen and (max-width: 768px) {
    .carousel-container {
        width: 100%;
        max-width: 600px;
    }


}

@media only screen and (max-width: 700px) {
    :deep(.slick-arrow.custom-slick-arrow) {
        display: none !important;
    }
}

@media only screen and (max-width: 576px) {
    .banner-container {
        padding-bottom: 20px;
    }

    :deep(.ant-carousel .slick-dots li) {
        margin-inline: 2px;
    }

    :deep(.ant-carousel .slick-dots li button) {
        width: 5px;
        height: 5px;
    }

    :deep(.slick-arrow.custom-slick-arrow) {
        display: none !important;
    }
}
</style>